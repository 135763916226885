import React from 'react';
import type { SVGAttributes } from 'react';

export const AudioIcon = React.forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          clipRule="evenodd"
          d="M10.4354 0.350349C10.1703 0.249783 9.87773 0.313782 9.68575 0.524056L5.09629 5.49749H2.3993C2.02446 5.49749 1.71362 5.80833 1.71362 6.18316V10.5806C1.71362 10.9555 2.02446 11.2663 2.3993 11.2663H5.0323L9.6949 16.0935C9.82289 16.2306 10.0057 16.3037 10.1886 16.3037C10.2709 16.3037 10.3623 16.2855 10.4446 16.258C10.7005 16.1575 10.8743 15.9015 10.8743 15.6181V0.990314C10.8743 0.706901 10.7006 0.450915 10.4354 0.350349ZM9.5029 13.9176L5.81854 10.0961C5.69055 9.95895 5.50769 9.88581 5.32484 9.88581H3.08497V6.8597H5.39799C5.58997 6.8597 5.77282 6.77742 5.90082 6.64028L9.5029 2.7365V13.9176ZM12.4833 4.60146C12.1085 4.60146 11.7977 4.9123 11.7977 5.28714V11.0834C11.7977 11.4582 12.1085 11.7691 12.4833 11.7691C12.8582 11.7691 13.169 11.4582 13.169 11.0834V5.28714C13.169 4.9123 12.8582 4.60146 12.4833 4.60146ZM13.9917 4.58316C13.9917 4.20832 14.3026 3.89748 14.6774 3.89748C15.0522 3.89748 15.3631 4.20832 15.3631 4.58316V11.7782C15.3631 12.153 15.0522 12.4639 14.6774 12.4639C14.3026 12.4639 13.9917 12.153 13.9917 11.7782V4.58316Z"
        />
      </svg>
    );
  },
);

export const ComputerIcon = React.forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 17"
        ref={ref}
        {...props}
      >
        <path
          fill="currentColor"
          clipRule="evenodd"
          d="M1.94737 1.67578H3.00415C3.78283 1.67578 4.41318 2.30614 4.41318 3.08482V13.5228C4.41318 14.3015 3.78283 14.9319 3.00415 14.9319H1.94737C1.16869 14.9319 0.53833 14.3015 0.53833 13.5228V3.08482C0.53833 2.30614 1.16869 1.67578 1.94737 1.67578ZM3.00415 13.7731C3.1432 13.7731 3.25443 13.6619 3.25443 13.5228V3.08482C3.25443 2.94577 3.1432 2.83453 3.00415 2.83453H1.94737C1.80832 2.83453 1.69708 2.94577 1.69708 3.08482V13.5228C1.69708 13.6619 1.80832 13.7731 1.94737 13.7731H3.00415ZM5.36808 3.65957H15.9544H15.9637C16.2788 3.65957 16.5384 3.90986 16.5384 4.23431V11.261C16.5384 11.5761 16.2788 11.8357 15.9637 11.8357H12.5708V12.327H12.8582C13.1734 12.327 13.433 12.5866 13.433 12.9017C13.433 13.2169 13.1734 13.4765 12.8582 13.4765H7.91733C7.60215 13.4765 7.34258 13.2169 7.34258 12.9017C7.34258 12.5866 7.60215 12.327 7.91733 12.327H8.21396V11.8357H5.36808C5.0529 11.8357 4.79333 11.5854 4.79333 11.261V4.23431C4.79333 3.91913 5.0529 3.65957 5.36808 3.65957ZM9.37271 12.3363H11.4028V11.845H9.37271V12.3363ZM5.94282 10.6862H15.3704V4.81832H5.94282V10.6862ZM2.47587 10.6398C2.23485 10.6398 2.04018 10.8345 2.04018 11.0755C2.04018 11.3165 2.23485 11.5112 2.47587 11.5112C2.71689 11.5112 2.91156 11.3165 2.91156 11.0755C2.91156 10.8345 2.71689 10.6398 2.47587 10.6398ZM2.01234 3.97472C2.01234 3.71516 2.21628 3.51122 2.47584 3.51122C2.7354 3.51122 2.93934 3.71516 2.93934 3.97472V7.97935C2.93934 8.23891 2.7354 8.44285 2.47584 8.44285C2.21628 8.44285 2.01234 8.23891 2.01234 7.97935V3.97472Z"
        />
      </svg>
    );
  },
);

export const HouseHoldItems = React.forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 71.13 65.83"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m49.38,30.04l5.7-.71c2.36-.3,4.5-1.52,5.95-3.41l9.35-12.16c1.1-1.43.97-3.46-.31-4.74l-6.91-6.91c-1.49-1.49-3.85-1.64-5.52-.36l-11.74,9.03c-1.89,1.45-3.11,3.59-3.41,5.96l-.71,5.7c-.04.36-.22.69-.49.93l-5.39,4.79-5.09-4.52c-.36-.33-.53-.75-.5-1.24.34-6.22-1.36-11.35-5.05-15.25C18.67.19,8.57,0,8.47,0,5.47,0,3.29,1.15,2.23,2.23,1.15,3.29,0,5.47,0,8.47c0,.1.19,10.2,7.15,16.79,3.9,3.69,9.03,5.39,15.27,5.05.46-.03.88.14,1.18.49l4.11,4.64-19.04,16.91c-2.4,2.04-3.36,5.41-2.17,8.6.72,1.95,2.28,3.54,4.21,4.32,3.24,1.29,6.71.33,8.75-2.08l16.45-18.53,16.44,18.51c1.44,1.7,3.55,2.67,5.8,2.67,2.03,0,4.01-.81,5.43-2.23,1.46-1.46,2.26-3.4,2.26-5.46,0-2.22-.97-4.33-2.66-5.79l-19.08-16.93,4.35-4.9c.24-.27.57-.44.93-.49Zm-23.53-1.21c-.91-1.04-2.22-1.59-3.6-1.5-5.37.29-9.75-1.13-13.03-4.23C3.26,17.45,2.99,8.56,2.99,8.47c0-2.23.84-3.62,1.33-4.1.02-.02.03-.03.05-.05.47-.5,1.87-1.33,4.1-1.33.09,0,8.99.27,14.62,6.22,3.1,3.28,4.53,7.66,4.23,13.02-.09,1.37.45,2.69,1.49,3.64l4.84,4.29-3.71,3.29-4.1-4.63Zm35.36,25.78c1.04.9,1.64,2.19,1.64,3.54,0,1.28-.48,2.43-1.39,3.34-.86.86-2.07,1.36-3.31,1.36-1.35,0-2.67-.61-3.52-1.62,0-.01-.02-.02-.03-.03l-16.69-18.79,4.2-4.73,19.09,16.94Zm-20.33-20.06h-.01s-23.67,26.67-23.67,26.67c-1.28,1.5-3.43,2.07-5.43,1.24-1.13-.47-2.04-1.41-2.46-2.56-.74-1.96-.17-4.02,1.33-5.29l26.17-23.24s.03-.03.05-.05l6.43-5.71c.81-.72,1.34-1.72,1.47-2.8l.71-5.7c.2-1.57,1.01-2.99,2.26-3.95l11.87-9.13s.01,0,.02,0l.64-.52c.4-.32,1-.24,1.32.15.28.35.26.77,0,1.09h0l-11.42,10.46c-.61.56-.65,1.5-.09,2.11.3.32.7.49,1.1.49.36,0,.72-.13,1.01-.39l11.15-10.22.31-.29s.04-.02.06-.04c.37-.26.87-.24,1.2.09.34.34.34.81.05,1.16h0l-10.51,11.49c-.41.44-.57,1.1-.31,1.65.29.6.84.89,1.38.89.4,0,.81-.16,1.1-.49l10.46-11.42h0c.34-.29.83-.32,1.18-.02.39.34.48.89.15,1.28l-.93,1.12-8.81,11.45c-.96,1.25-2.39,2.07-3.95,2.26l-5.7.71s0,0,0,0c-.95,0-1.85.41-2.48,1.11l-5.66,6.37Z"
          />
        </g>
      </svg>
    );
  },
);

export const SmartHouseIcon = React.forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M39.6945 57.8047H53.2506V35.395M57.5008 29.2956L31.9995 6.19531L6.49826 29.2956M10.7485 35.395V57.8047H24.3046"
          stroke="currentColor"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.5556 33.7778C26.4357 26.6667 37.5643 26.6667 44.4444 33.7778"
          stroke="currentColor"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 39.1111C28.4222 35.5556 35.5778 35.5556 40 39.1111"
          stroke="currentColor"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32 48C33.4728 48 34.6667 46.8061 34.6667 45.3333C34.6667 43.8606 33.4728 42.6667 32 42.6667C30.5272 42.6667 29.3333 43.8606 29.3333 45.3333C29.3333 46.8061 30.5272 48 32 48Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
